
<script>
import InviteErrorHandler from './InviteErrorHandler.vue'
import Loading from '@/components/general/Loading'
export default {
  components: { InviteErrorHandler, Loading },
  data () {
    return {
      isReady: false,
      error: false,
      prospectResponse: {}
    }
  },
  created () {
    this.$store.dispatch('attemptDeclineProspectInvite', this.$route.params.hash).then((data) => {
      this.isReady = true
      this.prospectResponse = data
      console.log(data)
    }).catch((err) => {
      this.isReady = true
      this.error = true
      this.prospectResponse = err.response
    })
  }
}
</script>
<template>
  <section class="firstAccess-selection--container">
    <div class="firstAccess--header">
        <img :src="'/assets/images/primary-logo.svg'" />
    </div>
    <div v-if="isReady">
      <invite-error-handler :response="prospectResponse" v-if="error"/>
      <v-container v-else class="firstAccess--body">
          <div class="welcome-decline-content-wrapper">
              <h1>{{$t('prospect.invite.decline.title', {company: prospectResponse.company.name, position: prospectResponse.position.title})}}</h1>
              <h5> {{$t('prospect.invite.decline.subtitle')}}</h5>
          </div>
      </v-container>
    </div>
    <Loading v-else></Loading>
  </section>
</template>
<style lang="scss">
.welcome-content-wrapper {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin:auto;
  padding: 0 15%;
  text-align: justify;
    h1 {
      font-family: $lato;
      font-size: 72px;
      font-weight: 700;
    }
    h5 {
      color: #A0A0A0;
      font-family: $lato;
      font-size: 24px;
      font-weight: 400;
    }
}
.welcome-button-wrapper {
  button {
    width: 200px;
  }
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.firstAccess-selection--container {
  margin: 0 10%;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  .firstAccess--header {
    display: flex;
    width: 100vw;
    height: 64px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 0;
    left: 0;

    img {
      height: 24px;
    }
  }
  .firstAccess--body {
    .welcome-decline-content-wrapper{
      margin-top: 15vh;
      display:flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: left;
      h1{
        font-family: $lato;
        font-size: 50px;
      }
      h5 {
      margin-top: 2.25rem;
      color: #A0A0A0;
      font-family: $lato;
      font-size: 24px;
      font-weight: 400;
    }
    }
    .content-wrapper {
      display: flex;
      min-height: 100vh;
      min-width: 100vw;
      justify-content: center;
      align-items: center;
      margin-bottom: 200px;
    }
    .profile-form--actions {
      text-align: right;
      margin: 40px 0 80px;
    }
    max-width: 1100px;
    padding-top: 100px;
    .first-selection--title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #242321;
    }
    .first-subtitle {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #A0A0A0;
    }
    .first--stape {
      margin-top: 50px;
      text-align: left;
    }
  }
}
</style>
